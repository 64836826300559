<template>
<div class="sponsor-outer">
  <div class="sponsor center">
    <br>
    <ATitle :text="'展商风采'"/>
    <br>
    <div class="sponsor-list">
      <div v-for="(item, index) in arr" v-if="item.exhibitorDTOList.length>0" class="row">
        <DTitle class="title" :text="item.name"/>
        <br>
        <div class="flex item">
          <a v-for="img in item.exhibitorDTOList" v-if="img.logoUrl" target="_black" :href="img.officialUrl || false" class="flex img"><img :src="img.logoUrl"></a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data() {
    return {
      arr: []
    }
  },
  created() {
    this.$http.get('/meeting/showroom/listDetail',{
    }).then(res => {
      if (res.code === 0) {
        this.arr = res.data
      } else {
        this.$Message.warning(res.msg)
      }
    }).catch(err => {
      this.$Message.error(err)
    })
  }
}
</script>

<style lang="less" scoped>
.sponsor-outer {
  background: url('../assets/views_bg.png') no-repeat scroll bottom / 100%;
}
.sponsor-list {
  .title {
    margin: 7px;
  }
  .row {
    .item {
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-bottom: 30px;
      .img {
        width: 164px;
        height: 52px;
        border: 1px solid #DEDEDE;
        background: #FFF;
        border-radius: 3px;
        padding: 5px;
        margin: 5px 8px;
        img {
          max-width: 150px;
          max-height: 40px;
        }
      }
    }
  }
}
</style>
